<template>
  <div v-if="loadingExecution">Loading...</div>
  <div v-else-if="errorExecution">Error: {{ errorExecution.message }}</div>
  <div v-else-if="execution" class="st-view-wrapper">
    <div class="st-view-header-wrapper">
      <div class="st-view-header-content">
        <router-link
          id="policies"
          class="button breadcrumbs-item"
          to="/policies"
        >
          Policies
        </router-link>
        <span class="breadcrumbs-item">&lt;</span>
        <router-link
          class="button breadcrumbs-item"
          :to="{ path: '/policy/' + policyName }"
        >
          {{ policyName }}
        </router-link>
        <span class="breadcrumbs-item">&lt;</span>
        <span class="text-muted breadcrumbs-item"> {{ execution.uuid }} </span>
        <div class="st-metadata-wrapper">
          <div>
            <div>
              <div class="text-muted st-header-title-tag">
                EXECUTION DETAILS
              </div>

              <h5 class="st-header-title">
                {{ execution.uuid }}
              </h5>
            </div>
          </div>
          <div class="container ml-0 st-metadata-body-wrapper">
            <div class="row">
              <div class="col-md-3 st-meta-data-item-wrapper">
                <div class="st-meta-data-title-tag">POLICY NAME</div>
                <div class="st-meta-data-title" data-testid="policy-name">
                  {{ policyName }}
                </div>
              </div>
              <div class="col-md-3 st-meta-data-item-wrapper">
                <div class="st-meta-data-title-tag">PROVIDER</div>
                <div class="st-meta-data-title" data-testid="provider-name">
                  {{ execution.account.provider }}
                </div>
              </div>
              <div class="col-md-3 st-meta-data-item-wrapper">
                <div class="st-meta-data-title-tag">ACCOUNT ID</div>
                <div class="st-meta-data-title">
                  {{ execution.account.id }}
                </div>
              </div>
              <div class="col-md-3 st-meta-data-item-wrapper">
                <div class="st-meta-data-title-tag">REGION</div>
                <div class="st-meta-data-title">
                  {{ execution.paramRegion }}
                </div>
              </div>
              <div class="col-md-3 st-meta-data-item-wrapper">
                <div class="st-meta-data-title-tag">TIMESTAMP</div>
                <div class="st-meta-data-title">
                  {{ getUTCDateTime(execution.start) }}
                </div>
              </div>
              <div class="col-md-3 st-meta-data-item-wrapper">
                <div class="st-meta-data-title-tag">EXECUTION TIME</div>
                <div class="st-meta-data-title" data-testid="execution-time">
                  {{ execution.executionTime }}
                </div>
              </div>
              <div class="col-md-3 st-meta-data-item-wrapper">
                <div class="st-meta-data-title-tag">Resources</div>
                <div class="st-meta-data-title">
                  {{ execution.metricResources }}
                </div>
              </div>
              <div class="col-md-3 st-meta-data-item-wrapper">
                <div class="st-meta-data-title-tag">API CALLS</div>
                <div class="st-meta-data-title" data-testid="api-calls">
                  {{ execution.metricApiCalls }}
                </div>
              </div>
              <div class="col-md-3 st-meta-data-item-wrapper">
                <div class="st-meta-data-title-tag">AVG API RATE</div>
                <div class="st-meta-data-title" data-testid="avg-api-rate">
                  {{ execution.avgApiRate }}
                </div>
              </div>
              <div class="col-md-3 st-meta-data-item-wrapper">
                <div class="st-meta-data-title-tag">AVG RESOURCE / CALL</div>
                <div class="st-meta-data-title" data-testid="resource-per-call">
                  {{ execution.resourcePerCall }}
                </div>
              </div>
              <div class="col-md-3 st-meta-data-item-wrapper">
                <div class="st-meta-data-title-tag">IS DRY RUN</div>
                <div class="st-meta-data-title">--</div>
              </div>
              <div class="col-md-3 st-meta-data-item-wrapper">
                <div class="st-meta-data-title-tag">RAISED EXCEPTION</div>
                <div class="st-meta-data-title">--</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading">Loading...</div>
    <div v-else-if="error">Error: {{ error.message }}</div>
    <div v-else-if="policy" class="st-view-body-wrapper">
      <div class="st-view-body-content">
        <div class="table-wrapper-temp mb-5">
          <h3>Resource Listing</h3>
          <div class="my-3 mb-4">
            <div class="row d-flex justify-content-between">
              <div class="col"></div>
            </div>
          </div>
          <ResourceTable
            :resource-type="resourceType"
            :execution-u-u-i-d="execution.uuid"
            gql-query-key="resourceTableByExecution"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import ResourceTable from "../components/ResourceTable";
import { useQuery, useResult } from "@vue/apollo-composable";
import policyQuery from "../graphql/policy.query.gql";
import executionQuery from "../graphql/execution.query.gql";
import {
  getTimeMillis,
  millisToTime,
  getUTCDateTime,
} from "@/utils/datetime.js";
export default {
  name: "ExecutionDetail",
  components: {
    ResourceTable,
  },
  setup() {
    const route = useRoute();
    const policyName = route.params.policyName;
    const executionID = route.params.executionID;
    const { result, loading, error } = useQuery(policyQuery, {
      name: policyName,
    });

    const policy = useResult(result, {}, (data) => data.policy);

    const resourceType = useResult(result, "", (data) => data.policy.resource);

    const {
      result: resultExecution,
      loading: loadingExecution,
      error: errorExecution,
    } = useQuery(executionQuery, {
      uuid: executionID,
    });

    const execution = useResult(resultExecution, {}, (data) => {
      const newExecution = { ...data.execution };
      const startTime = getTimeMillis(data.execution.start);
      const endTime = getTimeMillis(data.execution.end);
      const executionTimeInMilliSec = endTime - startTime;
      const executionTimeInSec = executionTimeInMilliSec / 1000;
      newExecution.executionTime = millisToTime(executionTimeInMilliSec);
      newExecution.avgApiRate = (
        data.execution.metricApiCalls / executionTimeInSec
      ).toFixed(2);
      newExecution.resourcePerCall = (
        data.execution.metricResources / data.execution.metricApiCalls
      ).toFixed(2);
      return newExecution;
    });

    return {
      loading,
      loadingExecution,
      errorExecution,
      error,
      route,
      policyName,
      policy,
      executionID,
      execution,
      resourceType,
      getUTCDateTime,
    };
  },
};
</script>

<style lang="scss" scoped>
.table-wrapper-temp {
  margin-top: 70px;
}

.st-header-title {
  margin-top: 8px;
}
</style>
